import React from "react";

import company_img from "../../img/company_img.png";

export const Company_top = () => {
    return (
        <div className="company">
            <div className="page_top">
                <img className="page_top_icon" src={company_img} alt="company_icon"></img>
                <h1 className="page_top_h1">Company</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">どんな会社が運営してる？</p>
                <p className="page_top_taxt_p">イースマイルホールディングスです。</p>
            </div>
        </div>
    )
}
import React from "react";

export const Career_text4 = () => {
    return (
        <div className="career_main">
            <h1 className="page_main_h1">ご相談方法</h1>
            <p className="page_main_p">&nbsp;キャリアカウンセリング申し込みページより申し込みフォーマットに沿ってご入力ください。</p>
            <p className="page_main_p">&nbsp;営業日２～３日以内に担当者方ご連絡差し上げます。</p>
            <p className="page_main_p">&nbsp;メールベースでのご相談とご来社頂いての面談形式のご相談がございます。</p>
            <p className="page_main_p">&nbsp;ご来社頂いてのご相談の場合、日程のご調整の上、初回90分、2回目以降は50分が1回分の想定時間になります。</p>
            <p className="page_main_p">&nbsp;※平日、土曜日の11時～20時までの時間帯となります。日曜日、祝日はご対応できませんのであらかじめご了承ください。</p>
            <p className="page_main_p">&nbsp;いずれの場合もご相談内容は絶対に第三者に漏らしませんので安心してご相談ください。</p>
            <p className="page_main_p">&nbsp;現在、キャリアカウンセリングは完全無料にて対応しております。</p>
            <p className="page_main_p">&nbsp;将来的にサービスの一部が有料になる可能性はありますが、サービスが変更する場合は、必ず事前にお伝えいたします。</p>
            <p className="page_main_p">&nbsp;キャリアカウセリングを継続して利用されている方に関しましても料金が発生する際には、必ず事前に説明の上の実施いたしますので安心してご利用ください。急に料金が発生することはありません。</p>
        </div>
    )
}
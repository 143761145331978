import React from "react";

import { useNavigate } from "react-router-dom";

import consultation_img from "../../img/consultation_img.jpg";

export const Future_counseling = () => {

    const navigate = useNavigate()
    return (
        <div className="future_counseling">
            <h1 className="page_main_h1"><span className="page_main_span">&nbsp;–</span> キャリアカウンセリング <span className="page_main_span">–</span></h1>
            
            <div className="counseling_box">
                <figure className="counseling_img"><img src={consultation_img} alt="counseling_img"></img></figure>
                <div className="futuer_text">
                    <p className="future_main_p"><span className="page_main_span_color">※希望している方のみ対象。</span></p>
                    <p className="future_main_p">「自分に向いている仕事がわからない」、「この方向性でいいか自信がない」、お仕事に関する悩みや自分のこれからの進路に関する悩みを、キャリアカウンセラーがお話をお聞きします。</p>
                    <p className="future_main_p">2016年4月に新設されました、国家資格キャリアコンサルタントの有資格者も在籍しており、技術的なサポートと悩みや進路のサポートの両面からバックアップいたします。</p>
                    <p className="futuer_counseling_link"><a className="futuer_link_a" onClick={() => navigate('/Career')}>カウンセリングとは？詳細ページへ</a></p>
                </div>
            </div>
        </div>
    )
}
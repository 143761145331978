import React from "react";

export const Career_text2 = () => {
    return (
        <div className="career_main">
            <h1 className="page_main_h1">&nbsp;相談事例</h1>
            <p className="page_main_p">&nbsp;・ゲーム業界にぼんやり行きたいと思っているが、自分でも整理できていない。</p>
            <p className="page_main_p">&nbsp;・今の職場を辞めようと思っているが、踏ん切りがつかない、誰かに相談したい。</p>
            <p className="page_main_p">&nbsp;・パソコンを使った仕事に興味があり、IT業界かゲーム業界かのどちらかで悩んでいる。</p>
            <p className="page_main_p">&nbsp;・IT業界やゲーム業界で働きたいと思っているが、どんな業務内容があるか知りたい。</p>
        </div>
    )
    



}
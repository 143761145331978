import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Future_top } from "../component/future_page/Future_top";
import { Future_counseling } from "../component/future_page/Future_counseling";
import { Future_scout } from "../component/future_page/Future_scout";
import { Register } from "../component/common/Register";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";

export const Future = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>学習した後は？ | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Future_top />

        <Future_counseling />
        <Future_scout />

        <div className="futuer_register"><Register /></div>

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
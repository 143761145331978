import React from "react";

import membership_img from "../../img/membership_img.png";

export const Membership_top = () => {
    return (
        <div className="membership">
            <div className="page_top">
                <img className="page_top_icon" src={membership_img} alt="membership_icon"></img>
                <h1 className="page_top_h1">Membership</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">会員規約</p>
                <p className="page_top_taxt_p">学習前に必ずお読みください。​</p>
            </div>
        </div>
    )
}
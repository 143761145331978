import React from "react";

export const Select_text = () => {
    return (
        <div className="slects_text">
            <h1 className="select_text_h1">様々な学習コースが選択可能。</h1>
            <p className="select_text_p">ゲームエンジニア、ゲームデザイナー、Webエンジニア、サーバーエンジニア etc</p>
            <p className="select_text_p">学習コースを職種・分野に分ける事で、あなたが興味を持っている分野を集中して学習できます。</p>
        </div>
    )
}
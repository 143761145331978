import React from "react";

import question_img from "../../img/question_img.png";

export const Question_top = () => {
    return (
        <div className="question">
            <div className="page_top">
                <img className="page_top_icon" src={question_img} alt="question_icon"></img>
                <h1 className="page_top_h1">Question</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">質問いろいろ</p>
                <p className="page_top_taxt_p">みなさんからの質問をまとめました。</p>
            </div>
        </div>
    )
}
import React from "react";


import { Slider } from "../common/Slider";

export const Study_FF2 = () => {
    return (
        <div className="study_ff">
            <h1 className="page_main_h1"><span className="page_main_span">&nbsp;FF 2.</span> 様々な学習コースが選択可能。</h1>
            <div className="stydy_slider"><Slider /></div>
            <p className="page_main_p"><span className="page_main_span_bold">ゲームエンジニア、ゲームデザイナー、Webエンジニア</span>など、学習できるコースを職種事に分ける事で、あなたの目指す道を最短で進む事ができます。</p>
            <p className="page_main_p">もちろん、他のコースを併行して学習を進める事も可能です。</p>
            <br/>
            <br/>
            <br/>
            <p className="page_main_p">学習課題は随時更新され、初級者～上級者まで幅広い層のユーザーが満足できる課題の作成を目指しています。</p>
            <p className="page_main_p">初級編は解説を見ながら学習を進められるのて、プログラミング初級者でも抵抗なく学習を進める事ができます。</p>
            <p className="page_main_p">中級～上級編は、実戦に近い形式での課題になっている為、すぐに仕事で生かせるような内容になっています。</p>
        </div>
    )
}
import React from "react";

export const Scroll_down = () => {
    const scroll_down = () => {
        const register_left = document.getElementById("register").getBoundingClientRect().left;
        const register_top = document.getElementById("register").getBoundingClientRect().top;
        window.scrollTo({
          left: register_left,
          top: register_top,
          behavior: "smooth",
        });
      };
    return (
    <div className="scroll_down">

        <p className="register_scroll"><a className="scroll_down_button" onClick={scroll_down}>会員登録へ</a></p>
        <a className="scroll_down_decoration"></a>

    </div>  
  );
}
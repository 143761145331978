import React from "react";

import future_img from "../../img/future_img.png";

export const Future_top = () => {
    return (
        <div className="future">
            <div className="page_top">
                <img className="page_top_icon" src={future_img} alt="future_icon"></img>
                <h1 className="page_top_h1">Future</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">学習した後は？</p>
                <p className="page_top_taxt_p">IT業界の仕事に興味を持っている人</p>
                <p className="page_top_taxt_p">あなたの将来を一緒に作ります。</p>
            </div>
        </div>
    )
}
import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Privacy_top } from "../component/privacy_page/Privacy_top";
import { Privacy_main } from "../component/privacy_page/Privacy_main";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";


export const Privacy = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>プライバシーポリシー | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Privacy_top />
        <Privacy_main />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
import React from "react";

export const Study_FF3 = () => {
    return (
        <div className="study_ff">
            <h1 className="page_main_h1"><span className="page_main_span">&nbsp;FF 3.</span> 学習課題を評価してもらいながらスキルアップ。</h1>
            <p className="page_main_p">学習した課題をアップロードする事で、IT教育のプロフェッショナルチームから採点・評価をもらう事ができます。</p>
            <p className="page_main_p">提出した課題がクリア条件に満たない場合は、評価アドバイスを参考に再度チャレンジする事が可能です。</p>
            <div className="study_score">
                <div className="study_score_change">
                    <p className="study_score_before">Before</p>
                    <p className="study_score_try">Let’s try!</p>
                    <p className="study_gauge_before"></p>
                    <p className="study_score_number">0 スコア</p>
                </div>
                <div className="study_score_change">
                    <p className="study_score_after">After</p>
                    <p className="study_score_perfect">Perfect!</p>
                    <p className="study_gauge_after"></p>
                    <p className="study_score_number">100 スコア</p>
                </div>
            </div>
            <p className="page_main_p">採点・評価をもらう事で、自分が今「どのくらいのスキルがあるのか？」、「どんなものが作れるのか？」をステータスとして視覚化する事ができます。</p>
            <p className="page_main_p">自分１人の学習ではモチベーションを保つのも大変ですが、学習内容を評価してもらう事でモチベーションの維持にもつながります。</p>
            <p className="page_main_p">課題を１つ１つクリアしていけば、着実にスキルアップする事ができます。</p>
        </div>
    )
}
import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Contact_top } from "../component/contact_page/Contact_top";
import { Contact_main } from "../component/contact_page/Contact_main";
import { Contact_modal } from "../component/contact_page/Contact_modal";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";



export const Contact = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>お悩み相談 | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Contact_top />
        <Contact_main />
        <Contact_modal />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
import React from "react";

export const Question_main = () => {

    return (
        <div className="question_main">
            <h2 className="page_main_h2"><span className="page_main_span">&nbsp;１.</span> 学習システムについて</h2>

            <input id="question_check1" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check1"><span className="page_main_span_q">Q</span>どこから学習を始めれば良いですか？</label>
            <div className="question_box">
            <p className="question_text_p">まずは自分の学習したいコースを選び、プログラミング初級者の方はレベル1からスタートしてみてください。</p>
            </div>

            <input id="question_check2" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check2"><span className="page_main_span_q">Q</span>学習した課題はどうやって評価してもらえるのでしょうか？</label>
            <div className="question_box">
            <p className="question_text_p">学習した課題をアップロードする画面があります。</p>
            <p className="question_text_p">その画面からアップロードしてください。</p>
            </div>

            <input id="question_check3" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check3"><span className="page_main_span_q">Q</span>各コースに同じ課題がありますが、なぜでしょうか？</label>
            <div className="question_box_bottpm">
            <p className="question_text_p">違うコースであっても必要なスキルだからです。</p>
            <p className="question_text_p">ただし、1度クリアしてしまえば、同じ課題はクリアした事になります。安心してください。</p>
            </div>

            <h2 className="page_main_h2"><span className="page_main_span">&nbsp;２.</span> 就職・転職について</h2>

            <input id="question_check4" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check4"><span className="page_main_span_q">Q</span>ここで学習すれば、希望の職種に就く事ができますか？</label>
            <div className="question_box">
            <p className="question_text_p">それはあなたの頑張り次第です。</p>
            <p className="question_text_p">しっかりと課題をこなし、良い評価をPRできれば、希望する就職先を探す事も可能になります。</p>
            </div>

            <input id="question_check5" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check5"><span className="page_main_span_q">Q</span>給料などの条件交渉は可能でしょうか？</label>
            <div className="question_box">
            <p className="question_text_p">もちろん条件交渉が可能です。</p>
            <p className="question_text_p">学習システムのクリア率、コミュニケーション力、将来性などが交渉の材料になります。</p>
            </div>

            <input id="question_check6" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check6"><span className="page_main_span_q">Q</span>将来ゲームの開発に携わる仕事がしたいのですが・・・。</label>
            <div className="question_box_bottpm">
            <p className="question_text_p">もちろん技術と経験があればゲーム開発の現場に行く事ができます。</p>
            <p className="question_text_p">弊社の社員の多くが、ゲーム開発に携わっており、有名なタイトルの現場にで開発を行っている社員もいます。</p>
            </div>

            <h2 className="page_main_h2"><span className="page_main_span">&nbsp;３.</span> カウンセリングについて</h2>
            
            <input id="question_check7" className="question_check" type="checkbox"/>
            <label className="question_label" htmlFor="question_check7"><span className="page_main_span_q">Q</span>自分が将来どのような分野のエンジニアになりたいか迷っています・・・。</label>
            <div className="question_box">
            <p className="question_text_p">まずはカウンセリングを受けてみませんか？</p>
            <p className="question_text_p">どんな内容でもけっこうです。あなたの道を見つけてあげられるかもしれません。</p>
            </div>
            
        </div>
    )
}
import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Career_top } from "../component/career_page/Career_top";
import { Career_text1 } from "../component/career_page/Career_text1";
import { Career_text2 } from "../component/career_page/Career_text2";
import { Career_text3 } from "../component/career_page/Career_text3";
import { Career_text4 } from "../component/career_page/Career_text4";
import { Career_text5 } from "../component/career_page/Career_text5";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";


export const Career = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>キャリアカウンセリング | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Career_top />
        <Career_text1 />
        <Career_text2 />
        <Career_text3 />
        <Career_text4 />
        <Career_text5 />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
import React from "react";
import { useNavigate } from "react-router-dom";

import bottom_logo from "../../img/logo.png";

export const Footer = () => {
    const navigate = useNavigate()
    return (
        <footer className="footer">

            <a onClick={() => navigate('/')} className="footer_logo"><img src={bottom_logo} alt="footer_logo"></img></a>

            <div className="footer_about">
                <a onClick={() => navigate('/Membership')}>会員規約</a> / 
                <a onClick={() => navigate('/Privacy')}>プライバシーポリシー</a> / 
                <a onClick={() => navigate('/Company')}>運営会社</a>
            </div>

            <div className="footer_copyright">Copyright © 2018 E-SMILE. Co. Ltd. All Rights Reserved.</div>

        </footer> 
    )
}
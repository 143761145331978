import React from "react";

import { useNavigate } from "react-router-dom";

export const Register = () => {
  
    const navigate = useNavigate()
    return (
      <div id="register">
        <div className="register">
        <h1　className="register_text_h1">まずは、会員登録してみませんか？</h1>
          <p className="register_text">下のボタンをクリックすると、会員登録ページへ移動します。ユーザー登録を行う事で、無料でプログラミング学習をスタートできます。</p>
          <p className="register_link"><a className="register_link_a" href="https://smile-fortissimo.com/app/users/add">会員登録ページへ</a></p>
        </div>
      </div>
      
  );
}
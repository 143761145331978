import React from "react";

export const Company_main = () => {

    return (
        <div className="company_main">
            <table className="company_table">
                <tbody>
                    <tr className="company_table_tr">
                        <th className="company_table_th">会社名</th>
                        <td className="company_table_td">イースマイルホールディングス株式会社<br/>E SMILE HD CO.,LTD.</td>
                    </tr>

                    <tr className="company_table_tr">
                        <th className="company_table_th">住所</th>
                        <td className="company_table_td">〒103-0016<br/>東京都中央区日本橋小網町１８－３ 小網町ゼネラルビル</td>
                    </tr>

                    <tr className="company_table_tr">
                        <th className="company_table_th">代表者</th>
                        <td className="company_table_td">	代表取締役社長　塩住　誠</td>
                    </tr>

                    <tr className="company_table_tr">
                        <th className="company_table_th">社員数</th>
                        <td className="company_table_td">	約240名（イースマイルグループ全体 : 2017年04月現在）</td>
                    </tr>

                    <tr className="company_table_tr">
                        <th className="company_table_th">電話</th>
                        <td className="company_table_td">電話	TEL：03-5645-1766<br/>FAX：03-5645-1767</td>
                    </tr>

                    <tr className="company_table_tr">
                        <th className="company_table_th">URL</th>
                        <td className="company_table_td"><a href="http://www.esmile-hd.com/">http://www.esmile-hd.com/</a></td>
                    </tr>

                    <tr className="company_table_tr">
                        <th className="company_table_th">アクセス</th>
                        <td className="company_table_td">地下鉄メトロ日比谷線　人形町駅　徒歩５分<br/>地下鉄メトロ半蔵門線線　三越前駅　徒歩５分</td>
                    </tr>
                </tbody>
            </table>
        </div>

    )
}
import React from "react";

import { useNavigate } from "react-router-dom";

export const Study_FF4 = () => {

    const navigate = useNavigate()
    return (
        <div className="study_ff">
            <h1 className="page_main_h1"><span className="page_main_span">&nbsp;FF 4.</span> スキル次第で就職や転職時の採用条件がアップ。</h1>
            <p className="page_main_p"><span className="page_main_span_color">※就職や転職を希望している方のみ対象</span></p>
            <p className="page_main_p">将来IT業界で仕事をしたいという人は、是非このサービスをご利用ください。</p>
            
            <br/>
            <br/>
            <p className="page_main_p"><span className="page_main_span_bold">– 進路相談 –</span>など、学習できるコースを職種事に分ける事で、あなたの目指す道を最短で進む事ができます。</p>
            <p className="page_main_p">学習によって自分のスキルにある程度自信がついてきたら、将来自分がどのような分野で仕事をしたいか、なんとなく道が見えてくると思います。</p>
            <p className="page_main_p">そうなってきたら、まずは「キャリアカウンセリング」を受けてみましょう。</p>
            <p className="page_main_p">キャリアカウンセリング専門の講師が「現在のスキルや経験でどのような仕事があるか？」、「給料はどのくらいになるか？」、「働き方について」など色々な悩みに対して答えを提示してくれます。</p>
            <p className="study_counseling_link"><span className="study_link_a" onClick={() => navigate('/Career')}>カウンセリングとは？詳細ページへ</span></p>
            
            <p className="page_main_p"><span className="page_main_span_bold">– 就職／転職 –</span></p>
            <p className="page_main_p">学習状況や現状のスキルをスカウトチームがチェックし、好条件でのスカウトを提示してくる場合があります。</p>
            <p className="page_main_p">あなたの頑張り次第で大変な就職活動をスキップする事ができ、且つ好条件での採用を決められる可能性が高くなります。</p>
            <p className="page_main_p">より良い条件を提示してもらえるよう、スキル情報などを常に最新にしておきましょう。</p>
        </div>
    )
}
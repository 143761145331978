import React from "react";

import career_img from "../../img/career_img.png";

export const Career_top = () => {
    return (
        <div className="career">
            <div className="page_top">
                <img className="page_top_icon" src={career_img} alt="career_icon"></img>
                <h1 className="page_top_h1">Career</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">キャリアカウンセリング</p>
                <p className="page_top_taxt_p">進路の事ならプロにお任せください。​</p>
            </div>
        </div>
    )
}
import React from "react";

export const Study_FF1 = () => {
    return (
        <div className="study_ff">
            <h1 className="page_main_h1"><span className="page_main_span">&nbsp;FF 1.</span> 無料のITエンジニア育成サイト。</h1>
            <p className="page_main_p">Fortissimoは、無料で学習できるエンジニア育成サイトです。</p>
            <p className="page_main_p">まずは会員登録を行ってから、学習システムにログインする事で学習をスタートできます。</p>
            <p className="page_main_p">システム内で自動課金されてしまうなどといった事もないので、安心して利用する事ができます。</p>
            <p className="page_main_p">プログラミング学習に興味を持っている人は、是非試しに会員登録を行ってみてください。</p>
            <p className="study_register_link"><a className="study_link_a" href="https://smile-fortissimo.com/app/users/add">会員登録ページへ</a></p>
        </div>
    )
}
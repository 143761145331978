import './App.css';
import './App_responsive.css';
import './reset.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Top_page } from './pages/Top_page';
import { Study } from './pages/Study';
import { Future } from './pages/Future';
import { Question } from './pages/Question';
import { News } from './pages/News';
import { Contact } from './pages/Contact';
import { Membership } from "./pages/Membership";
import { Privacy } from "./pages/Privacy";
import { Company } from "./pages/Company";
import { Career } from "./pages/Career";

function App() {
  return (
    <BrowserRouter>
    <div>
        <Routes>
            <Route path="/" element={<Top_page />} />
            <Route path="/Study" element={<Study />} />
            <Route path="/Future" element={<Future />} />
            <Route path="/Question" element={<Question />} />
            <Route path="/News" element={<News />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Membership" element={<Membership />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/Company" element={<Company />} />
            <Route path="/Career" element={<Career />} />
        </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;

import React from "react";

import contact_img from "../../img/contact_img.png";

export const Contact_top = () => {
    return (
        <div className="contact">
            <div className="page_top">
                <img className="page_top_icon" src={contact_img} alt="contact_icon"></img>
                <h1 className="page_top_h1">Contact</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">学習した後は？</p>
                <p className="page_top_taxt_p">IT業界の仕事に興味を持っている人</p>
                <p className="page_top_taxt_p">あなたの将来を一緒に作ります。</p>
            </div>
            <div className="page_middle_taxt">
                <p className="page_middle_taxt_p">Fortissimoでは、ゲーム系とビジネス系の分野を中心に、IT教育のプロフェッショナルチームがあなたの悩みを解決します。</p>
                <p className="page_middle_taxt_p">さらに、IT業界への就職、転職などを考えている人に対してもキャリアカウンセリング専門の講師が対応しています。</p>
                <p className="page_middle_taxt_p">「プログラミング学習を始めたいんだけど、何から学習すれば良いかわからない？」</p>
                <p className="page_middle_taxt_p">「ある程度スキルは身についてきたけど、自分にはどんな仕事が合っているんだろう？」など</p>
                <p className="page_middle_taxt_p">どんな質問でもかまいませんので、まずは問い合わせてみてください。</p>
            </div>
        </div>
    )
}
import React from "react";
import { useNavigate } from "react-router-dom";

import top_logo from "../../img/logo.png";

export const Header = () => {

    function navHamburger() {
        const body = document.body;
        body.classList.toggle('nav-open');
    };

    function navBlack() {
        const body = document.body;
        body.classList.remove('nav-open');
    };

    navHamburger();
    navBlack();

    const navigate = useNavigate()
    return (
        <header className="header">

            <a onClick={() => navigate('/')} className="header_logo"><img src={top_logo}  alt="header_logo"></img></a>

            <nav className="header_nav_pc">
                <ul className="nav_pc_list">
                    <li className="page_1"><a onClick={() => navigate('/Study')}>学習しよう！</a></li>
                    <li className="page_2"><a onClick={() => navigate('/Future')}>学習した後は？</a></li>
                    <li className="page_3"><a onClick={() => navigate('/Question')}>質問いろいろ</a></li>
                    <li className="page_4"><a onClick={() => navigate('/News')}>FF News</a></li>
                    <li className="page_5"><a onClick={() => navigate('/Contact')}>お悩み相談</a></li>
                    <li className="page_login"><a href="https://smile-fortissimo.com/app/">ログイン</a></li>
                </ul>
            </nav>

            <div className="header_nav_down">
                <nav className="header_nav_mobile">
                    <ul className="nav_mobile_list">
                    <li className="page_1"><a onClick={() => navigate('/Study')}>学習しよう！</a></li>
                    <li className="page_2"><a onClick={() => navigate('/Future')}>学習した後は？</a></li>
                    <li className="page_3"><a onClick={() => navigate('/Question')}>質問いろいろ</a></li>
                    <li className="page_4"><a onClick={() => navigate('/News')}>FF News</a></li>
                    <li className="page_5"><a onClick={() => navigate('/Contact')}>お悩み相談</a></li>
                    <li className="page_login"><a href="https://smile-fortissimo.com/app/">ログイン</a></li>
                    </ul>
                </nav>
                <div className="nav_hamburger" onClick={navHamburger}>
                    <span className="hamburger_line1"></span>
                    <span className="hamburger_line2"></span>
                    <span className="hamburger_line3"></span>
                </div>
                <div className="nav_black" onClick={navBlack}></div>
            </div>

        </header> 
    )
}
import React from "react";

export const Contact_modal = () => {

  const modal_true_clause = (event) => {
    if(event.target == document.querySelector('.modal_true')){
      document.querySelector('.modal_true').style.display = "none";
    }
  }

  const modal_false_clause = (event) => {
    if(event.target == document.querySelector('.modal_false')){
      document.querySelector('.modal_false').style.display = "none";
    }
  }

  const modal_clause = (event) => {
    event.preventDefault();
    document.querySelector('.modal_true').style.display = "none";
    document.querySelector('.modal_false').style.display = "none";
  }

    return (
        <div className="contact_modal">
            <div className="modal_true" onClick={modal_true_clause}>
              <div className="modal_true_text">
                <p className="modal_text_p">お問合せ内容を送信しました。</p>
                <p className="modal_clause_p" onClick={modal_clause}>×</p>
              </div>
            </div>

            <div className="modal_false" onClick={modal_false_clause}>
              <div className="modal_false_text">
                <p className="modal_text_p">ERROR:送信が完了できませんでした。</p>
                <p className="modal_clause_p" onClick={modal_clause}>×</p>
              </div>
                
            </div>

        </div>
    )
}
import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Study_top } from "../component/study_page/Study_top";
import { Study_FF1} from "../component/study_page/Study_FF1";
import { Study_FF2} from "../component/study_page/Study_FF2";
import { Study_FF3} from "../component/study_page/Study_FF3";
import { Study_FF4} from "../component/study_page/Study_FF4";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";


export const Study = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>学習しよう！ | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Study_top />

        <Study_FF1 />
        <Study_FF2 />
        <Study_FF3 />
        <Study_FF4 />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
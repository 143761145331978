import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Question_top } from "../component/question_page/Question_top";
import { Question_main } from "../component/question_page/Question_main";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";


export const Question = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>FAQ | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Question_top />
        <Question_main />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
import React from "react";

import Slick_slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Slider = () => {
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 5000,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

    return (
      <Slick_slider {...settings}>
      <div className="slider_img1">
        <p className="slider_img_text">Webプログラマ</p>
      </div>
      <div className="slider_img2">
        <p className="slider_img_text">ゲームデザイン/プログラミング</p>
      </div>
      <div className="slider_img3">
        <p className="slider_img_text">サーバー/ネットワーク構築</p>
      </div>        
    </Slick_slider>
  );
}
import React from "react";

import study_img from "../../img/study_img.png";

export const Study_top = () => {
    return (
        <div className="stydy">
            <div className="page_top">
                <img className="page_top_icon" src={study_img} alt="stydy_icon"></img>
                <h1 className="page_top_h1">Study</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">Fortessimoって一体どんなサイト？？</p>
                <p className="page_top_taxt_p">学習するといいことがたくさんあるんです。</p>
            </div>
        </div>
    )
}
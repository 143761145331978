import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Company_top } from "../component/company_page/Company_top";
import { Company_main } from "../component/company_page/Company_main";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";


export const Company = () => {
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>運営会社 | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <Company_top />
        <Company_main />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
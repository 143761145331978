import React from "react";

import scout_img from "../../img/scout_img.jpg";

export const Future_scout = () => {

    return (
        <div className="future_scout">
            <h1 className="page_main_h1"><span className="page_main_span">&nbsp;–</span> スカウト <span className="page_main_span">–</span></h1>
            
            <div className="scout_box">
                <figure className="scout_img"><img src={scout_img} alt="scout_img"></img></figure>
                <div className="futuer_text">
                    <p className="future_main_p"><span className="page_main_span_color">※希望している方のみ対象。</span></p>
                    <p className="future_main_p">学習状況や現状のスキルをスカウトチームがチェックし、好条件でのスカウトを提示してくる場合があります。</p>
                    <p className="future_main_p">あなたの頑張り次第で、大変な就職活動を飛び越えられ、且つ好条件での採用を決められる可能性が高くなります。</p>
                    <p className="future_main_p">より良い条件を提示してもらえるよう、スキル情報などを常に最新にしておきましょう。</p>
                </div>
            </div>
        </div>
    )
}
import React from "react";

import { useNavigate } from "react-router-dom";

import study_img from "../../img/study_img.png";
import future_img from "../../img/future_img.png";
import contact_img from "../../img/contact_img.png";

export const Select = () => {
    
    const navigate = useNavigate()
    return (
        <div className="select">
                <div className="select_column">
                <h1 className="select_title">Study</h1>
                    <img className="select_img" src={study_img}></img>
                    <p className="select_text">あなたが興味が持っている分野を集中して学習できるので、楽しみながらスキルを身に付ける事ができます。さらに、学習内容に対して評価をもらいながら進められるので、対話的な学習が可能です。</p>
                    <div className="select_link1" onClick={() => navigate('/Study')}>
                    <p className="link_text">学習しよう！</p>
                    </div>
                    
                </div>
                <div className="select_column">
                <h1 className="select_title">Future</h1>
                    <img className="select_img" src={future_img}></img>
                    <p className="select_text">IT業界の仕事に興味を持っている人は、学習後に「キャリアカウンセリング」、「スカウト」などのサービスを利用する事で、進路相談はもちろん、就職や転職をより好条件で行う事も可能です。</p>
                    <div className="select_link2" onClick={() => navigate('/Future')}>
                    <p className="link_text">学習した後は？</p>
                    </div>
                    
                </div>
                <div className="select_column">
                <h1 className="select_title">Contact</h1>
                    <img className="select_img" src={contact_img}></img>
                    <p className="select_text">ゲーム系とビジネス系の分野を中心に、IT教育のプロフェッショナルチームがあなたの悩みを解決します。さらに、キャリアカウンセリング専門の講師がIT業界への就職、転職などをサポートします。</p>
                    <div className="select_link3" onClick={() => navigate('/Question')}>
                    <p className="link_text">お悩み相談</p>
                    </div>
                    
                </div>
        </div>
    )
}
import React from "react";

export const Privacy_main = () => {

    return (
        <div className="privacy_main">
            <h2 className="page_main_h3"><span className="page_main_span">|</span>個人情報保護に関する基本方針</h2>

            <div className="page_about_text">
                <p className="page_about_p">Fortissimo（以下「当サービス」といいます。）は、当サービスが運営するホームページを含めすべての業務において、お客様、採用応募者の方、当サービス従業員等、当サービスが個人情報をお預かりする方全ての個人情報(*1)を適切に保護することを当サービスの重要な社会的責務として考え、個人情報を適切に取り扱うため、以下のプライバシーポリシーを定め、個人情報の保護に努めてまいります。</p>
                <p className="page_about_p">注*1 ここでいう「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるものをいいます。</p>
                <p className="page_about_p">1. 当サービスでは、取引やサービスを提供するための個人情報を適法に収集し、本プライバシーポリシーに定める利用目的の達成に必要な範囲内で収集した個人情報を利用いたします。個人情報に人種・信条等の要配慮個人情報が含まれる場合には、法令により認められた場合を除き、ご本人の同意を得ることなく個人情報を取得しません。第三者から個人情報を取得する場合であって、法令上、第三者提供を受ける際の確認義務および記録作成・保存義務が発生する場合には、これを遵守します。</p>
                <p className="page_about_p">2. 収集したの個人情個人情報は、利用目的の範囲内で正確・完全・最新の内容に保つよう努め、紛失・破壊・改ざん・漏洩・不正アクセス等が生じないようにセキュリティ対策を講じて適正に管理いたします。</p>
                <p className="page_about_p">3. 個人情報保護法などの法令・ガイドラインを遵守して、個人情報を取扱ってまいります。</p>
                <p className="page_about_p">4. 当サービスは、個人情報の取扱いが適正に行われるよう従事者への教育を実施し、当該適正な取扱いにつき定期的に点検するとともに個人情報保護の取り組みを必要に応じて随時見直し、改善してまいります。</p>
                <p className="page_about_p">5. 当サービスでは、社会情勢の変化、技術の進歩、個人情報保護法の改正等に応じ、事前の予告なく本プライバシーポリシーを変更することがあります。</p>
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>個人情報の収集及び利用目的</h2>

            <div className="page_about_text">
                <p className="page_about_p">当サービスは、ご提供いただいた個人情報を下記に定める目的のために利用します。</p>  
                <p className="page_about_p">1. 当サービスのお問い合せサービスの申込の受付</p>
                <p className="page_about_p">2. 本規約に違反したことがある者からの申請である場合</p>
                <p className="page_about_p">3. 当サービスのお問い合せサービスをご利用いただく条件等の確認・対応</p>
                <p className="page_about_p">4. 市場調査、データ分析やアンケートの実施および分析等によるサービスの研究や開発</p>
                <p className="page_about_p">5. 当サービスおよび当サービスの関連会社や提携会社のサービスのご提案</p>
                <p className="page_about_p">6. その他、お取引を適切かつ円滑に履行するための業務</p>
                <p className="page_about_p">7. 採用応募目的でご提供いただいた個人情報は、採用に関するご案内やご連絡、採用選考に使用させていただくものとし採用選考後は当サービスにて再利用不可能な状態で廃棄させていただきます。</p>
                <p className="page_about_p">8. 当サービス従業員から提出された個人情報は、社会保険業務や給与計算業務などの業務に必要な範囲内で使用するものとしそれ以外の目的では使用しません。</p>
            
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>第三者への委託</h2>
            <div className="page_about_text">
                <p className="page_about_p">当サービスは、お預かりした個人情報の処理を利用目的の範囲内で第三者に委託する場合があります。これらの第三者は、十分な個人情報のセキュリティー水準にあることを確認の上選定し、個人情報の適切な管理のための措置、秘密保持、再提供の禁止等個人情報維持管理に関する事項について、委託契約等を通じて、必要かつ適切な監督を行います。外国にある第三者に業務委託を行う場合であって、法令上、記録作成・保存義務が発生する場合には、これを遵守します。</p>
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>第三者提供</h2>

            <div className="page_about_text">
                <p className="page_about_p">当サービスは、ご本人の同意を得たとき、個人情報保護法その他の法令等に基づく場合を除き、第三者に個人情報を提供しません。第三者に個人情報を提供する場合であって、法令上、第三者提供を行う際の記録作成・保存義務が発生する場合には、これを遵守します。</p>
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>共同利用</h2>

            <div className="page_about_text">
                <p className="page_about_p">イースマイルホールディングスグループでは、イースマイルホールディングスグループとして一体的に行われる経営管理業務の遂行並びにお客様への商品・サービス等のご案内・ご提供及びその判断のために、グループ会社間で、以下のとおり、個人データを共同して利用することがあります。</p>
                <p className="page_about_p">1. 個人データの項目</p>  

                <div className="page_about_list">
                    <p className="page_about_p">イースマイルホールディングスグループ各社が保有する氏名、住所、電話番号、電子メールアドレス、性別、生年月日その他申込書等に記載されたお取引に関する情報</p>
                </div>  

                <p className="page_about_p">2. 共同利用するグループ会社の範囲</p>  

                <div className="page_about_list">
                    <p className="page_about_p">イースマイルエンジニアリング株式会社</p>
                    <p className="page_about_p">イースマイル株式会社</p>
                    <p className="page_about_p">イースマイルシステムズ株式会社</p>
                    <p className="page_about_p">socialsharing株式会社</p>
                </div>  

                <p className="page_about_p">3. 個人データ管理責任者</p>  

                <div className="page_about_list">
                    <p className="page_about_p">イースマイルホールディングス株式会社</p>
                </div> 
            </div> 

            <h2 className="page_main_h3"><span className="page_main_span">|</span>個人情報保護方針に関するご意見、苦情、申し立て</h2>

            <div className="page_about_text">
                <p className="page_about_p">当個人情報保護方針に関するご質問、または事項の遵守がなされていないとご判断された場合は、下記お問合せ窓口までご連絡下さい。内容の確認後、速やかにご返答し、必要があればお客様と連絡・協議させていただき、誠心誠意をもって解決させていただきます。</p>
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>個人情報の開示、訂正、削除等について</h2>

            <div className="page_about_text">
                <p className="page_about_p">当サービスは、ご本人が自らの個人情報の開示、訂正、利用の停止、または消去などを希望される場合、ご本人であることを確認したうえですみやかに対応しますので、下記お問合せ窓口までご連絡下さい。</p>
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>Google Analytics の利用について</h2>

            <div className="page_about_text">
                <p className="page_about_p">本サイトにおいてはサービス向上のためGoogle, Inc.のGoogle Analyticsを利用してサイトの計測を行っております。これに付随して生成されるテキストファイル「Cookie」を通じて分析を行うことがありますが、この際、IPアドレス等のユーザ様情報の一部が、Google, Inc.に収集される可能性があります。サイト利用状況の分析、サイト運営者へのレポートの作成、その他のサービスの提供目的に限りこれを使用します。利用者は、本サイトを利用することで、上記方法および目的においてGoogleが行うこうしたデータ処理につき許可を与えたものとみなします。</p>    
            </div>

            <h2 className="page_main_h3"><span className="page_main_span">|</span>お問合せ窓口</h2>

            <div className="page_about_text">
                <p className="page_about_p">〒103-0016 東京都中央区日本橋小網町18-3 小網町ゼネラルビル</p>    
                <p className="page_about_p">TEL：03-5645-1766（代）</p>    
                <p className="page_about_p">改定日：平成29年7月21日</p>    
            </div>

        </div>

    )
}
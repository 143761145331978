import React from "react";

export const Career_text1 = () => {
    return (
        <div className="career_main">
            <h1 className="page_main_h1">&nbsp;キャリアカウンセリングとは</h1>
            <p className="page_main_p">&nbsp;「自分に向いている仕事がわからない」、「この方向性でいいか自信がない」、お仕事に関する悩みや自分のこれからの進路に関する悩みを、キャリアカウンセラーがお話をお聞きします。</p>
            <p className="page_main_p">&nbsp;2016年4月に新設されました、国家資格キャリアコンサルタントの有資格者も在籍しており、技術的なサポートと悩みや進路のサポートの両面からバックアップいたします。</p>
        </div>
    )
}

import React from "react";

import privacy_img from "../../img/privacy_img.png";

export const Privacy_top = () => {
    return (
        <div className="privacy">
            <div className="page_top">
                <img className="page_top_icon" src={privacy_img} alt="privacy_icon"></img>
                <h1 className="page_top_h1">Privacy</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">プライバシーポリシー</p>
                <p className="page_top_taxt_p">あなたの情報をしっかりと管理します。​</p>
            </div>
        </div>
    )
}
import React, {useState, useEffect} from "react";
import $ from 'jquery';

import { News_pegination } from "./News_pegination";



export const News_main = () => {;
    
    const [news, setNews] = useState([]);
    const [state, setState] = useState({ selectPage: 0, displayCount: 10});
    const [count, setCount] = useState(0)

    const { selectPage, displayCount } = state;

    const fetchNews = (page = 0) =>{
        $.ajax({
            url: 'https://smile-fortissimo.com//app/admin/news/ajConnect/',
            type:'GET'
        })
        .done(function(res) {
            const end = (page+1) * displayCount;
            const start = end - displayCount;
 
            setCount(parseInt(res.count));
 
            setNews(res.news.slice(start, end));
        })
    }

    useEffect(() => {

        fetchNews();
        
    },[]);

    const setStateInfoAction = (value, type) => {
        switch(type) {
            case 'News_select_page':
                setState({ ...state, selectPage: value });
                fetchNews(value);
                break;
            default:
                break;
        }
    };

    return (
        <div className="news_main">

            <div className='news_column'>
                { news.map(( news, index ) => {
                    return (
                    <div key={index} id="news_column">
                        <input id={news.id} className="news_check" type="checkbox"/>
                        <label htmlFor={news.id} className="news_label" id="news_label"><span className="page_main_span_date">{news.date.substring(0,10)}</span>{news.title}</label>
                        <div className="news_box" id="news_box">{news.text}</div>
                    </div>
                    );
                })}

                <News_pegination listLength={count} displayCount={displayCount} setStateInfoAction={setStateInfoAction} />

            </div>
            
        </div>
    )
}


import React, {useState} from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { News_top } from "../component/news_page/News_top";
import { News_main } from "../component/news_page/News_main";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";

export const News = () => {

  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>FF News | Fortissimo</title>
          </Helmet>
        </HelmetProvider>
          
        <Header />

        <News_top />
        <News_main />

        <Footer />

        <Scroll />
        <Scroll_top />

      </main>
    </div>
  );
}
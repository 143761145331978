import React, {useState, useEffect} from "react";
import $ from 'jquery';

import { useNavigate } from "react-router-dom";

export const FFnews = () => {
  
    const navigate = useNavigate()

    const [news, setNews] = useState([]);

    useEffect(() => {

        $.ajax({
            url: 'https://smile-fortissimo.com/app/admin/news/ajConnect/',
            type:'GET'
        })
        .done(function(res) {
            setNews(res.news.slice(0, 5));
        })

    },[]);
    

    return (
      <div className="ffnews">
          <h1　className="ffnews_h1">FF News<a className="ffnews_a">新着情報</a></h1>

          <div className='news_column'>
                { news.map(( news, index ) => {
                    return (
                    <div key={index} id="news_column" className={news.id}>
                        <input id={news.id} className="news_check" type="checkbox"/>
                        <label htmlFor={news.id} className="news_label" id="news_label"><span className="page_main_span_date">{news.date.substring(0,10)}</span>{news.title}</label>
                        <div className="news_box" id="news_box">{news.text}</div>
                    </div>
                    );
                })}
            </div>

          <p className="news_text_p" onClick={() => navigate('/News')}><span className="page_main_span_bold">過去のニュースを見る ＞</span></p>
      </div>
  );
}

import React from "react";

export const Scroll_top = () => {
  const scroll_top = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="scroll_top">

        <a className="scroll_top_button" onClick={scroll_top}></a>

    </div>  
  );
}
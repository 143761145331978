import React from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from "../component/common/Header";
import { Slider_text } from "../component/top_page/Slider_text";
import { Slider } from "../component/common/Slider";
import { Select_text } from "../component/top_page/Select_text";
import { Select } from "../component/top_page/Select";
import { Register } from "../component/common/Register";
import { FFnews } from "../component/top_page/FFnews";
import { Footer } from "../component/common/Footer";
import { Scroll } from "../component/common/Scroll";
import { Scroll_top } from "../component/common/Scroll_top";
import { Scroll_down } from "../component/top_page/Scroll_down";

export const Top_page = () => {

  
  return (
    <div>
      <main>

        <HelmetProvider>
          <Helmet>
            <title>無料のITエンジニア育成サイト | Fortissimo</title>
          </Helmet>
        </HelmetProvider>

        <Header />

        <Slider_text />
        <Scroll_down />  
        <Slider /> 

        <Select_text />
        <Select />

        <Register />

        <FFnews />

        <Footer />

        <Scroll />
        <Scroll_top />
      
       </main>
    </div>
    
  );
}
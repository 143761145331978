import React,{useState} from "react";
import $ from 'jquery';

export const Contact_main = () => {
    const [name, setName] = useState("");
    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    
    const [email, setEmail] = useState("");
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    
    const [redio, setRedio] = useState("");
    const handleRedioChange = (event) => {
        setRedio(event.target.value);
    }
    
    const [title, setTitle] = useState("");
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }
    
    const [contents, setContents] = useState("");
    const handleContentsChange = (event) => {
        setContents(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let form_data = {"name":name,"email":email,"redio":redio,"title":title,"contents":contents};

        let form_flag = true;

        if (name == ""){
            document.querySelector('.mandatory1').style.display = "none";
            form_flag = false;
            document.querySelector('.column1').style.display = "flex";
            document.querySelector('.validation1').style.display = "block";
        }else if(name !== ""){
            document.querySelector('.mandatory1').style.display = "none";
            document.querySelector('.validation1').style.display = "none";
        }

        if (email == ""){
            document.querySelector('.mandatory2').style.display = "none";
            form_flag = false;
            document.querySelector('.column2').style.display = "flex";
            document.querySelector('.validation2').style.display = "block";
        }else if(email !== ""){
            document.querySelector('.mandatory1').style.display = "none";
            document.querySelector('.validation2').style.display = "none";
        }

        if (redio == ""){
            document.querySelector('.mandatory3').style.display = "none";
            form_flag = false;
            document.querySelector('.column3').style.display = "flex";
            document.querySelector('.validation3').style.display = "block";
        }else if(redio !== ""){
            document.querySelector('.mandatory1').style.display = "none";
            document.querySelector('.validation3').style.display = "none";
        }

        if (contents == ""){
            document.querySelector('.mandatory4').style.display = "none";
            form_flag = false;
            document.querySelector('.column4').style.display = "flex";
            document.querySelector('.validation4').style.display = "block";
        }else if(contents !== ""){
            document.querySelector('.mandatory1').style.display = "none";
            document.querySelector('.validation4').style.display = "none";
        }

        if (form_flag == true){
            let last_check = window.confirm('入力したフォーム情報で送信しますか？');

            if (last_check){
                $.ajax({
                    url: 'https://smile-fortissimo.com//app/users/ajContactAnyone/',
                    type:'POST',
                    dataType: 'json',
                    data: form_data
                })
                .done(function(res) {
                    if(res.success == '0'){
                        document.querySelector('.modal_true').style.display = "block";
                        setTimeout(function() {
                            document.querySelector('.modal_true').style.display = "none";
                        }, 5000);
                    }else if(res.success == '1'){
                        document.querySelector('.modal_false').style.display = "block";
                        setTimeout(function() {
                            document.querySelector('.modal_false').style.display = "none";
                        }, 5000);
                    }
                })
                .fail(function() {
                    document.querySelector('.modal_false').style.display = "block";
                    setTimeout(function() {
                        document.querySelector('.modal_false').style.display = "none";
                    }, 5000);

                })
            }
        }
        
    };
    



    return (
        <form className="contact_main">
            
            <p className="contact_column_p column1">お名前
                <span className="page_main_span_mandatory1 mandatory1">※必須</span>
                <span className="page_main_span_validation1 validation1">※必須項目を入力してください。</span>
            </p>

            <input className="contact_form_input" name="name" id="name" defaultValue={name} onChange={handleNameChange} type="text" placeholder="例:笑顔 太郎"/>

            <p className="contact_column_p column2">メールアドレス
                <span className="page_main_span_mandatory2 mandatory2">※必須</span>
                <span className="page_main_span_validation2 validation2">※必須項目を入力してください。</span>
            </p>
            <input className="contact_form_input" name="email" id="email" defaultValue={email} onChange={handleEmailChange} type="text"placeholder="例:taro@smile.com"/>

            <p className="contact_column_p column3">相談内容
                <span className="page_main_span_mandatory3 mandatory3">※必須</span>
                <span className="page_main_span_validation3 validation3">※必須項目を入力してください。</span>
            </p>

            <label className="contact_redio_p"><input className="contact_redio" name="redio" id="0" defaultValue={redio} onChange={handleRedioChange} type="radio" defaultValue="技術的な相談"/>技術的な相談</label>
            <label className="contact_redio_p"><input className="contact_redio" name="redio" id="1" defaultValue={redio} onChange={handleRedioChange} type="radio" defaultValue="キャリアカウンセリング"/>キャリアカウンセリング</label>
            <label className="contact_redio_p"><input className="contact_redio" name="redio" id="2" defaultValue={redio} onChange={handleRedioChange} type="radio" defaultValue="その他"/>その他</label>
            

            <p className="contact_column_p">題名
                <span className="content_main_span_color">※任意</span>
            </p>

            <input className="contact_form_input" name="title" id="title" defaultValue={title} onChange={handleTitleChange} type="text"　placeholder="例:カウンセリングのご相談"/>

            <p className="contact_column_p column4" >お問合せ内容
                <span className="page_main_span_mandatory4 mandatory4">※必須</span>
                <span className="page_main_span_validation4 validation4">※必須項目を入力してください。</span>
            </p>

            <textarea className="contact_form_texte" name="contents" id="contents" defaultValue={contents} onChange={handleContentsChange} type="text"/>

            <p className="content_submit"><input type="submit" className="content_submit_a" onClick={handleSubmit}/></p>

        </form>
    )
}
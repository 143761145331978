import React from "react";

import news_img from "../../img/news_img.png";

export const News_top = () => {
    return (
        <div className="news">
            <div className="page_top">
                <img className="page_top_icon" src={news_img} alt="news_icon"></img>
                <h1 className="page_top_h1">FF News</h1>
            </div>
            <div className="page_top_taxt">
                <p className="page_top_taxt_p">新しい教材のお知らせや学習に役立つ</p>
                <p className="page_top_taxt_p">プログラミング情報をお届けします。</p>
            </div>
        </div>
    )
}


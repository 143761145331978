import React from "react";

export const Career_text5 = () => {
    return (
        <div className="career_main">
            <h1 className="page_main_h1">キャリアカウンセリング利用者の声</h1>
            <p className="page_main_p">&nbsp;匿名のアンケートを一部紹介します。</p>
            <p className="page_main_p">&nbsp;「自分の内面を見つめなおす機会ができて良かった。継続して受けてみたいと思う。」</p>
            <p className="page_main_p">&nbsp;「ポジティブなイメージを持ちやすくなった。自分が足りていない部分を認識でき、それに対してのアプローチなどを知ることが多いので、これからも受けておきたいと思います。」</p>
            <p className="page_main_p">&nbsp;「真摯に対応してくださったことが伝わってきて励みになりました。継続して受けてみたいと思いますし、後々結果に残せるように頑張ります。」</p>
        </div>
    )
}




import ReactPaginate from 'react-paginate';

let setStateOfSelectPage;

export const News_pegination = (props) => {
  const { listLength, displayCount, setStateInfoAction } = props; 
  setStateOfSelectPage = setStateInfoAction;

  return (
    <>
      <ReactPaginate
        style={{ marginTop: 10, marginBottom: 100}}
        pageCount={pageCountCalc(listLength, displayCount)}
        marginPagesDisplayed={0}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName='news_pagination'
        pageClassName='news_page_item'
        pageLinkClassName='news_page_link'
        activeClassName='news_active_page'
        previousLabel=''
        nextLabel=''
        breakLabel='...'
        breakClassName='news_page_item'
        breakLinkClassName='news_page_link'
      />
    </>
  );
}

const handlePageClick = page => {
  setStateOfSelectPage(page.selected, 'News_select_page');
};

const pageCountCalc = (len, count) => {
  if (len < count) {
    return 1;
  }
  return Math.ceil(len / count);
};

import React from "react";

export const Career_text3 = () => {
    return (
        <div className="career_main">
            <h1 className="page_main_h1">カウンセリングメニュー</h1>
            <p className="page_main_p">&nbsp;・通常カウンセリング</p>
            <p className="page_main_p">&nbsp;&nbsp;&nbsp;→初回90分。2回目以降50～60分のカウンセリングを行います。悩みを丁寧に聞き、内面を整理していきます。</p>
            <p className="page_main_p">&nbsp;・情報提供（業界、傾向、内容）</p>
            <p className="page_main_p">&nbsp;&nbsp;&nbsp;→ゲーム業界、IT業界の産業情報、職種による傾向、作業内容や求人情報等の情報を提供します。</p>
            <p className="page_main_p">&nbsp;・体験のあっ旋（職業体験、経験者の話）</p>
            <p className="page_main_p">&nbsp;&nbsp;&nbsp;→研修内容の閲覧や成果物の閲覧、テストプレイ。技術者の話を聞く機会を用意し、イメージを持ってもらいます。</p>
            <p className="page_main_p">&nbsp;・カードを使った内面整理</p>
            <p className="page_main_p">&nbsp;&nbsp;&nbsp;→キャリアカウンセリング用のカードを使用して、自分の傾向や内面を整理していきます。</p>
            <p className="page_main_p">&nbsp;・ジョブカードを使った経験整理</p>
            <p className="page_main_p">&nbsp;&nbsp;&nbsp;→ジョブカードを使って自分の経験の整理とキャリアプランを考えるお手伝いをします。</p>
            <p className="page_main_p">&nbsp;・トレーニング（ビジネススキル向上）</p>
            <p className="page_main_p">&nbsp;&nbsp;&nbsp;→前述の内面整理で見つかった課題に対して、トレーニングを行い、希望する方向に進むお手伝いをします。</p>
        </div>
    )
}